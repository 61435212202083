import { ApiError } from '../../components/errors';
import { CCApiService } from './api-service';
import { UIConfig } from './config-service';

export enum fusionInstaceTypes {
  MANAGED_FUSION = 'MANAGED_FUSION',
  SELF_HOSTED_FUSION = 'SELF_HOSTED_FUSION',
}

export class ManagedFusionApiService {
  static ApiService: CCApiService;
  static managedFusionInstancesApi: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.managedFusionInstancesApi = `${this.apiBasePath}/integratedsystems`;
  }

  static async getManagedFusionInstances(type, customerId?: string, filter = {}): Promise<any> {
    const queryParams = {
      type,
      ...filter,
    };
    const url = customerId
      ? `${this.apiBasePath}/customers/${customerId}/integratedsystems?${new URLSearchParams(queryParams)}`
      : `${this.managedFusionInstancesApi}?${new URLSearchParams(queryParams)}`;
    return this.ApiService.performFetch(url).then(res => res);
  }

  static async getManagedFusionInstance(managedFusionId: string): Promise<any> {
    return this.ApiService.performFetch(`${this.managedFusionInstancesApi}/${managedFusionId}`).then(res => res);
  }

  static async createManagedFusionInstance({ customerId, managedFusionInstance }) {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ ...{ ...managedFusionInstance } }),
    };
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${customerId}/integratedsystems`,
      fetchOptions
    ).catch(this._mapApiErrorCodeToMessage);
  }

  static async updateManagedFusionInstance({ customerId, managedFusionInstance }) {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify({ ...{ ...managedFusionInstance } }),
    };
    const url = customerId
      ? `${this.apiBasePath}/customers/${customerId}/integratedsystems/${managedFusionInstance.id}`
      : `${this.managedFusionInstancesApi}/${managedFusionInstance.id}`;
    return this.ApiService.performFetch(url, fetchOptions).catch(this._mapApiErrorCodeToMessage);
  }

  static async deleteManagedFusionInstance(id: string): Promise<any> {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${this.managedFusionInstancesApi}/${id}`, fetchOptions).then(res => res);
  }

  static _mapApiErrorCodeToMessage(error) {
    if (error instanceof ApiError && error.message === 'E0000038') {
      error.message = 'The Managed Fusion Install cannot be added. Please contact customer support.';
    }
    if (error instanceof ApiError && error.message === 'E0000001') {
      error.message = 'redirect_uris must be an array of absolute URIs.';
    }
    throw error;
  }
}
